var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"folderCard ma-2 py-1 primaryBg",attrs:{"width":"317","height":"56","outlined":"","elevation":"1"},on:{"click":function($event){return _vm.selectFolder(_vm.folder.Id)}}},[_c('v-list-item',{staticClass:"mt-n1",attrs:{"data-qaid":"item_resultsList"}},[_c('v-list-item-avatar',{staticClass:"custom-icon",attrs:{"tile":"","width":"43px"}},[_c('CustomIcon',{attrs:{"indicator":{ name: 'folder', tooltip: 'folder' },"isEdited":'',"view":"list"}})],1),_c('v-list-item-content',{staticClass:"py-0",staticStyle:{"height":"56px"}},[_c('v-list-item-title',{staticClass:"mb-1",attrs:{"data-qaid":"item_selectByTitle"}},[_c('div',{class:[
            'd-flex',
            'folderTitle',
            'body2',
            _vm.$vuetify.theme.dark ? 'white--text': 'primary--text',
            'font-weight-medium',
            'text-wrap'
          ]},[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-qaid":"text_folderTitle"}},[_vm._v(" "+_vm._s(_vm.folder.DisplayName)+" ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }